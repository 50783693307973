@font-face
  font-display swap
  font-family 'Inter Tight'
  font-style normal
  font-weight 300
  src local(''), url('../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-300.woff2') format('woff2')

@font-face
  font-display swap
  font-family 'Inter Tight'
  font-style normal
  font-weight 400
  src local(''), url('../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-400.woff2') format('woff2')

@font-face
  font-display swap
  font-family 'Inter Tight'
  font-style normal
  font-weight 500
  src local(''), url('../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-500.woff2') format('woff2')