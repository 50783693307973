@font-face {
  font-display: swap;
  font-family: 'Inter Tight';
  font-style: normal;
  font-weight: 300;
  src: local(''), url("../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-300.woff2") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter Tight';
  font-style: normal;
  font-weight: 400;
  src: local(''), url("../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-400.woff2") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter Tight';
  font-style: normal;
  font-weight: 500;
  src: local(''), url("../fonts/inter-tight-v2-latin_cyrillic-ext_cyrillic-500.woff2") format('woff2');
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong,
b {
  font-weight: 700;
}
:root {
  --color-link: #0033ab;
  --color-menu: #1a194b;
  --color-title: #1e1e1e;
  --color-text: #494949;
  --color-copy: #aeaeae;
  --color-btn: #f4f4f4;
  --color-bord: #fbfbff;
  --color-white: #fff;
  --color-black: #000;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  color: var(--color-text);
  background: var(--color-white);
  font-family: 'Inter Tight', Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  font-weight: 400;
  min-width: 32rem;
  height: 100%;
  line-height: 1.2;
}
::-webkit-scrollbar {
  width: 0.3rem;
}
::-webkit-scrollbar-track {
  background: var(--color-bord);
}
::-webkit-scrollbar-thumb {
  background: var(--color-copy);
}
.cursorWait {
  cursor: wait;
}
.noscript {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  background-color: var(--color-white);
  z-index: 999999;
}
.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 7.7rem;
}
@media (min-width: 640px) {
  .wrap {
    padding-top: 9.5rem;
  }
}
@media (min-width: 992px) {
  .wrap {
    padding-top: 0;
    padding-left: 31.25%;
  }
}
.box {
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 103rem;
  width: 100%;
}
@media (min-width: 1080px) {
  .box {
    padding-left: min(calc(3rem + 1.56vw), 6rem);
    padding-right: min(calc(3rem + 1.56vw), 6rem);
  }
}
.sublink {
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  color: var(--color-link);
  transition: 0.32s;
  position: relative;
}
@media (min-width: 992px) {
  .sublink {
    font-size: min(calc(1.4rem + 0vw), 1.4rem);
  }
}
@media (min-width: 992px) {
  .sublink--big {
    font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .sublink:hover {
    color: var(--color-link);
  }
}
.sublink:before {
  content: '';
  position: absolute;
  background: var(--color-link);
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
  height: 0.1rem;
  transition: 0.32s;
}
@media (min-width: 992px) {
  .sublink:before {
    width: 0;
    opacity: 0.2;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .sublink:hover:before {
    width: 100%;
    opacity: 1;
  }
}
.title {
  font-size: min(calc(1.4rem + 1.15vw), 3.6rem);
  font-weight: 500;
  color: var(--color-title);
  padding-bottom: 0.2rem;
  line-height: 1.4;
}
@media (min-width: 992px) {
  .title {
    font-size: min(calc(1.4rem + 0.63vw), 2.6rem);
  }
}
.text {
  line-height: 1.5;
  font-weight: 300;
}
.text>*:nth-child(n+2) {
  margin-top: min(calc(0.9rem + 0.47vw), 1.8rem);
}
.text i {
  font-style: italic;
}
.text table {
  overflow: hidden;
}
.text th {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  font-weight: 500;
  padding: min(calc(0.8rem + 0.42vw), 1.6rem) min(calc(1rem + 0.52vw), 2rem);
  text-align: left;
}
.text td {
  padding: min(calc(0.6rem + 0.31vw), 1.2rem) min(calc(1rem + 0.52vw), 2rem);
  border: 0.1rem solid var(--color-bord);
}
.text ul {
  display: grid;
  flex-direction: column;
  gap: min(calc(1rem + 0.52vw), 2rem) 0;
}
.text ul li {
  position: relative;
  padding-left: min(calc(0.75rem + 0.39vw), 1.5rem);
}
.text ul li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.text>a {
  display: block;
}
.text a {
  color: var(--color-link);
}
.text img + p:nth-child(n+2) {
  margin-top: min(calc(1.5rem + 0.78vw), 3rem);
}
.text h2:nth-child(n+2),
.text h3:nth-child(n+2),
.text h4:nth-child(n+2),
.text h5:nth-child(n+2),
.text h6:nth-child(n+2) {
  padding-top: min(calc(1.1rem + 0.57vw), 2.2rem);
}
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-title);
  padding-bottom: 0.2rem;
  line-height: 1.4;
}
.text h2 {
  font-size: min(calc(1.4rem + 0.63vw), 2.6rem);
}
.text h3 {
  font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
}
.text h4 {
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
}
.text h5 {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.text h6 {
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
}
.text iframe {
  max-width: 100%;
}
.alignright {
  float: right;
  margin-left: 4.7rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 640px) {
  .alignright {
    float: none;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.alignleft {
  float: left;
  margin-right: 2.7rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 640px) {
  .alignleft {
    float: none;
    margin-right: 0;
    margin-bottom: 0;
  }
}
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: min(calc(3rem + 1.56vw), 6rem) 0 0;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
}
.pagination__item {
  border-radius: 50%;
  border: 0.1rem solid rgba(0,0,0,0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: min(calc(0.15rem + 0.08vw), 0.3rem);
  min-width: 4rem;
  min-height: 4rem;
  transition: 0.32s;
  margin: 0.6rem 0.3rem;
}
.pagination__item:hover {
  background: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}
.pagination__item.active {
  cursor: default;
  border-color: var(--color-black);
  color: var(--color-black);
}
.pagination__item.active:hover {
  background: inherit;
}
.pagination__left,
.pagination__right {
  line-height: 0;
  padding: min(calc(0.4rem + 0.21vw), 0.8rem);
}
.pagination__right {
  transform: scale(-1);
}
.pagination__arr {
  fill: var(--color-copy);
  transition: 0.32s;
}
a:hover .pagination__arr {
  fill: var(--color-link);
}
.btn {
  display: inline-grid;
  grid-auto-flow: column;
  gap: min(calc(0.5rem + 0.26vw), 1rem);
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  background: var(--color-btn);
  border: 0.1rem solid var(--color-btn);
  transition: all 0.32s;
  padding: min(calc(0.25rem + 0.13vw), 0.5rem) min(calc(0.7rem + 0.36vw), 1.4rem);
  text-align: center;
  font-family: 'Inter Tight', Arial, sans-serif;
  min-height: min(calc(1.6rem + 0.83vw), 3.2rem);
  cursor: pointer;
  padding: 0.6rem 1.5rem;
  fill: var(--color-text);
}
@media (hover: hover) and (min-width: 992px) {
  .btn:hover {
    color: var(--color-title);
    fill: var(--color-title);
    border: 0.1rem solid var(--color-text);
  }
}
.btn.disabled,
.btn:hover.disabled {
  cursor: default;
  opacity: 0.35;
  color: var(--color-text);
  fill: var(--color-text);
  border: 0.1rem solid var(--color-btn);
}
.logo {
  display: block;
  max-width: 12.8rem;
}
@media (min-width: 640px) {
  .logo {
    max-width: 16.8rem;
  }
}
.nav__ul {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.1rem + 0.57vw), 2.2rem) 0;
  position: relative;
  text-transform: uppercase;
  min-height: 100%;
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
}
@media (min-width: 992px) {
  .nav__ul {
    font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
    gap: min(calc(0.75rem + 0.39vw), 1.5rem) 0;
  }
}
.nav__li--break {
  margin-top: auto;
  padding-top: min(calc(1rem + 0.52vw), 2rem);
}
@media (min-width: 992px) {
  .nav__li--break {
    padding-top: min(calc(1.9rem + 0.99vw), 3.8rem);
  }
}
.nav__link {
  position: relative;
}
@media (hover: hover) and (min-width: 992px) {
  .nav__link:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    transition: 0.32s;
    width: 0;
    height: 0.1rem;
    background: var(--color-white);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .nav__link:hover:before {
    opacity: 1;
    width: 100%;
  }
}
.soc {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: min(calc(1.05rem + 0.55vw), 2.1rem) min(calc(1.8rem + 0.94vw), 3.6rem);
  align-items: center;
}
.icoArr {
  width: 1.5rem;
  height: 1.2rem;
}
.icoCross {
  width: 1.4rem;
  height: 1.4rem;
}
.slider {
  position: relative;
  height: 100%;
  width: 100%;
}
.slider__wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  touch-action: pan-y;
  height: 100%;
}
.slider__inner {
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  transition-property: transform;
}
.slider__slide {
  flex-shrink: 0;
  height: 100%;
  width: 100%;
}
.slider__pagination {
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  gap: min(calc(0.6rem + 0.31vw), 1.2rem);
  z-index: 2;
  left: min(calc(2.6rem + 1.35vw), 5.2rem);
  bottom: min(calc(1.3rem + 0.68vw), 2.6rem);
}
.bullet {
  height: 0.2rem;
  background: var(--color-white);
  opacity: 0.5;
  width: min(calc(1.5rem + 0.78vw), 3rem);
  transition: 0.32s;
}
.bullet.active {
  opacity: 1;
  width: min(calc(3.2rem + 1.67vw), 6.4rem);
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: var(--color-menu);
  color: var(--color-white);
  padding: 1rem 2rem;
}
@media (min-width: 992px) {
  .header {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    width: 31.25%;
    height: 100%;
    padding: min(calc(1rem + 0.52vw), 2rem);
  }
}
.header.open {
  position: fixed;
  height: 100%;
  overflow: auto;
}
@media (min-width: 992px) {
  .header.open {
    height: auto;
  }
}
.header.fixed {
  position: fixed;
}
@media (min-width: 992px) {
  .header__box {
    display: grid;
    justify-content: center;
    height: 100%;
    padding: min(calc(2rem + 1.04vw), 4rem) min(calc(1rem + 0.52vw), 2rem) min(calc(1rem + 0.52vw), 2rem);
    grid-template-rows: auto 1fr;
    overflow: auto;
  }
}
.header__box::-webkit-scrollbar {
  width: 0.3rem;
}
.header__box::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.05);
}
.header__box::-webkit-scrollbar-thumb {
  background: var(--color-white);
}
@media (min-width: 992px) {
  .header__soc {
    margin-top: 18%;
  }
}
.header__logo {
  margin: auto;
}
@media (min-width: 992px) {
  .header__logo {
    margin-bottom: 18%;
  }
}
.header__inner {
  display: none;
  justify-content: center;
  gap: min(calc(1.7rem + 0.89vw), 3.4rem) 0;
  padding: min(calc(1.9rem + 0.99vw), 3.8rem) 2rem;
}
@media (min-width: 992px) {
  .header__inner {
    display: grid;
    background: transparent;
    grid-template-rows: 1fr auto;
    margin: 0;
    padding: 0;
    gap: 0;
  }
}
.header.open .header__inner {
  display: grid;
}
.header__nav {
  min-width: 20.8rem;
  padding: 0 min(calc(2.2rem + 1.15vw), 4.4rem);
}
.header__navToggle {
  position: absolute;
  width: 4rem;
  height: 3rem;
  z-index: 4;
  top: 50%;
  top: min(calc(3.2rem + 1.67vw), 6.4rem);
  transform: translate(0, -50%);
  right: 2rem;
}
@media (min-width: 992px) {
  .header__navToggle {
    display: none;
  }
}
.header__navToggle:before,
.header__navToggle:after {
  content: "";
}
.header__navToggle:before,
.header__navToggle:after,
.header__navToggle span {
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background: #fff;
  left: 0;
  border-radius: 0.2rem;
  transition: 0.3s all;
}
.header__navToggle:before {
  top: 0;
}
.header__navToggle:after {
  bottom: 0;
}
.header__navToggle span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__navToggle.open span {
  opacity: 0;
  width: 0;
}
.header__navToggle.open:before {
  transform: rotate(-45deg) translate(-0.55rem, 1.3rem);
}
.header__navToggle.open:after {
  transform: rotate(45deg) translate(-0.55rem, -1.25rem);
}
.footer {
  padding: 0 0 min(calc(2.5rem + 1.3vw), 5rem);
  color: var(--color-copy);
  text-align: center;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  margin-top: auto;
}
.footer__box {
  display: grid;
  gap: min(calc(0.5rem + 0.26vw), 1rem);
}
.upper {
  display: grid;
  padding: min(calc(1.25rem + 0.65vw), 2.5rem) 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  justify-content: center;
  align-items: center;
  transition: 0.32s;
}
.upper:before {
  content: '';
  border: 0.1rem solid var(--color-text);
  border-left: 0;
  border-bottom: 0;
  transform: rotate(-45deg);
  transition: 0.32s;
  width: 1.5rem;
  height: 1.5rem;
}
@media (hover: hover) and (min-width: 992px) {
  .upper:hover:before {
    transform: scale(1.2) rotate(-45deg);
  }
}
.content {
  position: relative;
  padding: 0.1rem 0 min(calc(2.2rem + 1.15vw), 4.4rem);
  display: flex;
  flex-direction: column;
  gap: min(calc(3.3rem + 1.72vw), 6.6rem);
}
@media (min-width: 992px) {
  .content {
    padding: min(calc(2.2rem + 1.15vw), 4.4rem) 0;
    gap: min(calc(2rem + 1.04vw), 4rem);
  }
}
.top__box,
.banner__box {
  padding: 0;
}
@media (min-width: 992px) {
  .top__box,
  .banner__box {
    padding: 0 2rem;
  }
}
@media (min-width: 1080px) {
  .top__box,
  .banner__box {
    padding: 0 min(calc(3rem + 1.56vw), 6rem);
  }
}
.top__box {
  display: grid;
  gap: min(calc(1rem + 0.52vw), 2rem);
}
.top__back {
  padding: 0 2rem;
}
@media (min-width: 992px) {
  .top__back {
    padding: 0;
  }
}
.top__img {
  width: 100%;
}
.top__menu,
.top__submenu {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 992px) {
  .top__menu,
  .top__submenu {
    padding-left: 0;
    padding-right: 0;
  }
}
.banner__slide {
  position: relative;
}
.banner__text {
  padding: min(calc(2.6rem + 1.35vw), 5.2rem);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 500;
  font-size: min(calc(1.4rem + 0.94vw), 3.2rem);
  line-height: 1.33;
  color: var(--color-white);
  text-shadow: 0 0 0.3rem rgba(0,0,0,0.3);
}
.brands__box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: min(calc(2.4rem + 1.25vw), 4.8rem) min(calc(2rem + 1.04vw), 4rem);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
}
@media (min-width: 540px) {
  .brands__box {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .brands__box {
    gap: min(calc(1.5rem + 0.78vw), 3rem) min(calc(2rem + 1.04vw), 4rem);
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .brands__box {
    grid-template-columns: repeat(6, 1fr);
  }
}
.brand {
  display: grid;
  color: var(--color-copy);
  gap: min(calc(0.5rem + 0.26vw), 1rem);
  transition: 0.32s;
  justify-content: center;
  align-items: end;
  text-align: center;
}
.brand__img {
  margin: 0 auto;
}
.brand__text {
  align-self: end;
  white-space: nowrap;
  margin: 0 auto;
  border-bottom: 0.1rem solid var(--color-copy);
  transition: 0.32s;
  line-height: 1;
}
@media (hover: hover) and (min-width: 992px) {
  .brand:hover {
    color: var(--color-title);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .brand:hover .brand__text {
    border-bottom: 0.1rem solid rgba(255,255,255,0);
  }
}
.about__title {
  padding: min(calc(0.2rem + 0.1vw), 0.4rem) 0 min(calc(1rem + 0.52vw), 2rem);
  text-transform: uppercase;
}
.about__sublink {
  padding: min(calc(1rem + 0.52vw), 2rem) 0 0;
}
.news__title {
  text-transform: uppercase;
}
.news__items {
  display: grid;
  gap: min(calc(1.9rem + 0.99vw), 3.8rem) min(calc(1.3rem + 0.68vw), 2.6rem);
  padding: min(calc(1rem + 0.52vw), 2rem) 0 0;
}
@media (min-width: 680px) {
  .news__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
.newsItem {
  line-height: 1.375;
  display: grid;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
}
.newsItem__img {
  width: 100%;
}
@media (min-width: 680px) {
  .newsItem__img {
    height: min(calc(9.5rem + 4.95vw), 19rem);
    object-fit: cover;
  }
}
@media (min-width: 992px) {
  .newsItem__img {
    height: min(calc(8rem + 4.17vw), 16rem);
  }
}
.newsItem__title {
  padding: min(calc(0.1rem + 0.05vw), 0.2rem) 0 0;
}
.newsItem__date {
  color: var(--color-copy);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  margin-top: auto;
}
.news__sublink {
  padding: min(calc(1.55rem + 0.81vw), 3.1rem) 0 0;
}
.newsPage__top {
  padding: min(calc(0.8rem + 0.42vw), 1.6rem) 0 min(calc(2rem + 1.04vw), 4rem);
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(0.9rem + 0.47vw), 1.8rem) 0;
}
.newsPage__date {
  color: var(--color-copy);
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 500;
  padding-right: min(calc(1.85rem + 0.96vw), 3.7rem);
}
@media (min-width: 992px) {
  .newsPage__link {
    font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  }
}
.contacts__items {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.1rem + 0.57vw), 2.2rem);
  padding: min(calc(1.4rem + 0.73vw), 2.8rem) 0 min(calc(2.3rem + 1.2vw), 4.6rem);
}
.contact__name {
  color: var(--color-copy);
  padding: 0 0 min(calc(0.3rem + 0.16vw), 0.6rem);
  line-height: 1.375;
}
.contact__text {
  font-weight: 300;
  line-height: 1.5;
}
.listNews__title {
  padding: 0 0 min(calc(1.8rem + 0.94vw), 3.6rem);
}
.listNews__brands {
  display: grid;
  gap: min(calc(2rem + 1.04vw), 4rem);
}
.listNews__brand {
  display: grid;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
}
.listNews__items,
.listNews__bottom {
  display: grid;
}
@media (min-width: 580px) {
  .listNews__items,
  .listNews__bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}
.listNews__items {
  gap: min(calc(2.4rem + 1.25vw), 4.8rem) min(calc(1.3rem + 0.68vw), 2.6rem);
}
.listNews__item {
  display: flex;
  flex-direction: column;
}
.listNews__img {
  width: 100%;
}
@media (min-width: 580px) {
  .listNews__img {
    height: min(calc(11rem + 5.73vw), 22rem);
    object-fit: cover;
  }
}
.listNews__date {
  padding: min(calc(0.8rem + 0.42vw), 1.6rem) 0 min(calc(0.4rem + 0.21vw), 0.8rem);
  color: var(--color-copy);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
}
.listNews__name {
  line-height: 1.375;
  margin-bottom: auto;
  padding: 0 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  color: var(--color-title);
}
.listNews__desc {
  line-height: 1.57;
  font-weight: 300;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
}
.listNews__bottom {
  gap: min(calc(0.6rem + 0.31vw), 1.2rem) min(calc(1.3rem + 0.68vw), 2.6rem);
}
.trade__title {
  padding-bottom: min(calc(1rem + 0.52vw), 2rem);
  text-transform: uppercase;
}
.trade__items {
  display: grid;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
  padding-top: min(calc(2rem + 1.04vw), 4rem);
}
.trade__city {
  position: relative;
  background: var(--color-bord);
  padding: min(calc(0.5rem + 0.26vw), 1rem) min(calc(2rem + 1.04vw), 4rem);
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
  font-weight: 500;
  line-height: 1.64;
  transition: 0.32s;
  cursor: pointer;
}
.trade__city:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 2.4rem;
  transform: translate(0, -75%) rotate(-135deg);
  border-left: 0.1rem solid var(--color-text);
  border-top: 0.1rem solid var(--color-text);
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.32s;
}
.trade__city.active:before {
  border-color: var(--color-white);
  transform: translate(0, -25%) rotate(45deg);
}
.trade__city.active {
  background: var(--color-title);
  color: var(--color-white);
}
.trade__inner {
  display: none;
  padding: 0 0 min(calc(1rem + 0.52vw), 2rem);
}
.trade__subtitle {
  padding: min(calc(1.5rem + 0.78vw), 3rem) 0 min(calc(1rem + 0.52vw), 2rem);
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 500;
  color: var(--color-title);
}
.trade__list {
  display: grid;
  gap: min(calc(1rem + 0.52vw), 2rem) min(calc(1.3rem + 0.68vw), 2.6rem);
}
@media (min-width: 580px) {
  .trade__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 880px) {
  .trade__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.point {
  padding: min(calc(1.3rem + 0.68vw), 2.6rem) min(calc(1rem + 0.52vw), 2rem);
  background: var(--color-bord);
  display: flex;
  flex-direction: column;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
}
.point__title {
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  color: var(--color-title);
  padding: 0 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  line-height: 1.375;
}
.point__desc {
  font-weight: 300;
  padding: 0 0 min(calc(0.5rem + 0.26vw), 1rem);
  line-height: 1.43;
}
.point__phones {
  display: grid;
  color: var(--color-title);
  font-weight: 500;
  padding: 0 0 min(calc(0.5rem + 0.26vw), 1rem);
  line-height: 1.57;
}
.point__site {
  margin-top: auto;
  color: var(--color-link);
}
.tehs__title {
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
}
.tehs__item {
  padding: min(calc(1.1rem + 0.57vw), 2.2rem) 0;
  display: grid;
  gap: min(calc(0.4rem + 0.21vw), 0.8rem) min(calc(1.35rem + 0.7vw), 2.7rem);
}
@media (min-width: 480px) {
  .tehs__item {
    grid-template-columns: 14.3% auto;
  }
}
.tehs__item:nth-child(n+2) {
  border-top: 0.1rem solid var(--color-btn);
}
.tehs__wrap {
  padding: 0 0 min(calc(0.6rem + 0.31vw), 1.2rem);
}
@media (min-width: 480px) {
  .tehs__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/4;
    padding: 0;
  }
}
.tehs__date {
  color: var(--color-copy);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  line-height: 1.285;
}
.tehs__name {
  color: var(--color-title);
  line-height: 1.375;
}
.tehs__desc {
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  font-weight: 300;
  line-height: 1.57;
}
.tehp__title {
  text-transform: uppercase;
  padding: 0 0 min(calc(0.8rem + 0.42vw), 1.6rem);
}
.tehp__date {
  color: var(--color-copy);
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 500;
}
.tehp__text {
  padding: min(calc(2rem + 1.04vw), 4rem) 0 0;
}
.company__title {
  padding: 0 0 min(calc(1.5rem + 0.78vw), 3rem);
}
.product__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 min(calc(1.5rem + 0.78vw), 3rem);
}
.product__title {
  padding-right: 3rem;
}
.product__anons {
  font-weight: 300;
}
.teh {
  padding: min(calc(2rem + 1.04vw), 4rem) 0 0;
}
.teh__inner {
  padding: 0 0 min(calc(1.15rem + 0.6vw), 2.3rem);
  display: grid;
  gap: min(calc(2.4rem + 1.25vw), 4.8rem) min(calc(1.3rem + 0.68vw), 2.6rem);
}
@media (min-width: 640px) {
  .teh__inner {
    align-items: start;
    grid-template-columns: auto 20.8rem;
  }
}
.teh__right {
  display: grid;
  gap: min(calc(1.7rem + 0.89vw), 3.4rem);
}
.teh a {
  color: var(--color-link);
}
.teh__left {
  display: grid;
}
@media (min-width: 1180px) {
  .teh__left {
    grid-template-columns: repeat(2, 1fr);
  }
}
.teh__item {
  border-bottom: 0.1rem solid var(--color-btn);
  padding: min(calc(0.6rem + 0.31vw), 1.2rem) 0;
}
.teh__title {
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
  text-transform: uppercase;
}
@media (min-width: 1180px) {
  .teh__title {
    padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
  }
}
.teh__name {
  color: var(--color-copy);
  padding: 0 0 min(calc(0.3rem + 0.16vw), 0.6rem);
  line-height: 1.375;
}
.teh__data {
  font-weight: 300;
  line-height: 1.5;
}
.add__name {
  text-transform: uppercase;
  padding: 0 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 500;
  color: var(--color-title);
}
.menu {
  display: flex;
  flex-wrap: wrap;
}
.menu__item {
  background: var(--color-black);
  padding: min(calc(0.15rem + 0.08vw), 0.3rem) min(calc(0.45rem + 0.23vw), 0.9rem);
  border: 0.1rem solid var(--color-black);
  text-transform: uppercase;
  color: var(--color-white);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  line-height: 1.43;
  transition: 0.32s;
  margin-right: 1rem;
  margin-bottom: min(calc(0.6rem + 0.31vw), 1.2rem);
}
@media (hover: hover) and (min-width: 992px) {
  .menu__item:hover {
    background: var(--color-white);
    color: var(--color-black);
  }
}
.submenu {
  font-weight: 300;
  padding-top: min(calc(0.6rem + 0.31vw), 1.2rem);
  padding-bottom: min(calc(0.4rem + 0.21vw), 0.8rem);
  border-bottom: 0.1rem solid var(--color-btn);
}
.submenu__item {
  display: inline-block;
  padding: min(calc(0.4rem + 0.21vw), 0.8rem) 0;
  margin-right: min(calc(1.6rem + 0.83vw), 3.2rem);
  position: relative;
}
.submenu__item:before {
  content: '';
  position: absolute;
  background: var(--color-text);
  left: 0;
  bottom: min(calc(0.4rem + 0.21vw), 0.8rem);
  width: 100%;
  opacity: 1;
  height: 0.1rem;
  transition: 0.32s;
}
@media (min-width: 992px) {
  .submenu__item:before {
    width: 0;
    opacity: 0.2;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .submenu__item:hover:before {
    width: 100%;
    opacity: 1;
  }
}
.submenu__item.active {
  font-weight: 500;
}
.submenu__cross {
  display: inline-block;
  fill: #b71818;
  width: 0;
  margin: 0;
  opacity: 0;
  transition: 0.32s;
}
.submenu__item.active .submenu__cross {
  width: 1.4rem;
  margin: 0 0 -0.1rem 0.6rem;
  opacity: 1;
}
.products__row {
  display: none;
}
.products__row.active {
  display: block;
}
.products__title {
  text-transform: uppercase;
  padding: 0 0 min(calc(1rem + 0.52vw), 2rem);
}
.products__items {
  display: grid;
  gap: min(calc(2rem + 1.04vw), 4rem) min(calc(1.3rem + 0.68vw), 2.6rem);
  padding: 0 0 min(calc(2rem + 1.04vw), 4rem);
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 540px) {
  .products__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 780px) {
  .products__items {
    grid-template-columns: repeat(4, 1fr);
  }
}
.products__item {
  display: none;
  font-weight: 300;
  gap: min(calc(0.6rem + 0.31vw), 1.2rem);
  transition: 0.32s;
}
.products__item.active {
  display: grid;
}
.products__item:hover {
  color: var(--color-link);
}
.products__name {
  line-height: 1.5;
}
.archive__wrap {
  background: var(--color-bord);
  padding-bottom: min(calc(1rem + 0.52vw), 2rem);
}
.archive__top {
  position: relative;
  padding: min(calc(2.5rem + 1.3vw), 5rem) 2rem min(calc(1.5rem + 0.78vw), 3rem);
  cursor: pointer;
}
@media (min-width: 1080px) {
  .archive__top {
    padding: min(calc(2.5rem + 1.3vw), 5rem) min(calc(2.6rem + 1.35vw), 5.2rem) min(calc(1.5rem + 0.78vw), 3rem);
  }
}
.archive__top:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 6.2rem;
  transform: translate(0, -25%) rotate(-135deg);
  border-left: 0.1rem solid var(--color-text);
  border-top: 0.1rem solid var(--color-text);
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.32s;
}
.archive__top.active:before {
  transform: translate(0, 25%) rotate(45deg);
}
.archive__title {
  text-transform: uppercase;
}
.archive__inner {
  display: none;
  padding: 0 2rem;
}
@media (min-width: 1080px) {
  .archive__inner {
    padding: 0 min(calc(2.6rem + 1.35vw), 5.2rem);
  }
}
.archive__items {
  padding: min(calc(1.5rem + 0.78vw), 3rem) 0 min(calc(0.5rem + 0.26vw), 1rem);
  display: grid;
  gap: min(calc(1.5rem + 0.78vw), 3rem) min(calc(1.3rem + 0.68vw), 2.6rem);
}
@media (min-width: 380px) {
  .archive__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 540px) {
  .archive__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 780px) {
  .archive__items {
    grid-template-columns: repeat(4, 1fr);
  }
}
.archive__item {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  transition: 0.32s;
}
.archive__item:hover {
  color: var(--color-link);
}
.archive__name {
  line-height: 1.43;
  margin-top: auto;
}
.answer {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  font-weight: 500;
  padding: min(calc(1rem + 0.52vw), 2rem) 0;
}
.answer--send {
  color: #008000;
}
.answer--error {
  color: #f00;
}
.form {
  padding: min(calc(0.8rem + 0.42vw), 1.6rem) 0 0;
  max-width: 54rem;
}
.form__label {
  color: var(--color-text);
  font-family: $fontC;
  letter-spacing: 0.04rem;
  color: var(--color-copy);
}
.form__select,
.form__input,
.form__textarea {
  margin: min(calc(0.2rem + 0.1vw), 0.4rem) 0 min(calc(1rem + 0.52vw), 2rem);
  border: 0.1rem solid var(--color-copy);
  font-family: $fontM;
  width: 100%;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  padding: min(calc(0.6rem + 0.31vw), 1.2rem) min(calc(0.4rem + 0.21vw), 0.8rem);
}
.form__select.error,
.form__input.error,
.form__textarea.error {
  background: #f00;
}
.form__select::placeholder,
.form__input::placeholder,
.form__textarea::placeholder {
  color: var(--color-text);
}
.form__select {
  cursor: pointer;
  appearance: none;
  position: relative;
  background: url("../images/ico_select.svg") no-repeat;
  background-position: right 1.5rem top 50%;
}
.form__input--hide,
.form__textarea--hide {
  display: none;
}
.form__textarea {
  padding: min(calc(0.5rem + 0.26vw), 1rem);
  min-height: 9.8rem;
  line-height: 1.5;
}
.form__accept {
  display: block;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  color: var(--color-link);
  margin: min(calc(0.8rem + 0.42vw), 1.6rem) 0 0;
}
.form__hide {
  display: none;
}
.form__submit {
  margin: min(calc(1.5rem + 0.78vw), 3rem) auto 0;
}
.form__btn {
  width: 100%;
  padding-top: min(calc(1rem + 0.52vw), 2rem);
  padding-bottom: min(calc(0.8rem + 0.42vw), 1.6rem);
  min-height: 5.2rem;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .form__btn {
    min-height: none;
  }
}
.form__btnWrap {
  display: flex;
  align-items: flex-end;
  padding-top: min(calc(0.6rem + 0.31vw), 1.2rem);
}
@media (min-width: 900px) {
  .form__btnWrap {
    padding-top: 0;
  }
}
