/* @media (hover: hover) and (min-width: $widthMobile) */
/* @media (min-width: $widthMobile) */

/* COMMON */

:root
  --color-link #0033AB
  --color-menu #1A194B
  --color-title #1E1E1E
  --color-text #494949
  --color-copy #AEAEAE
  --color-btn #F4F4F4
  --color-bord #FBFBFF
  --color-white #FFF
  --color-black #000

html
  font-size 62.5%
  scroll-behavior smooth

body
  color var(--color-text)
  background var(--color-white)
  font-family $fontIT
  fs(16)
  fw(4)
  min-width 32rem
  height 100%
  line-height 1.2

::-webkit-scrollbar
  width .3rem

::-webkit-scrollbar-track
  background: var(--color-bord)

::-webkit-scrollbar-thumb
  background: var(--color-copy)

.cursorWait
  cursor wait

.noscript
  position fixed
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  padding 0 2rem
  background-color var(--color-white)
  z-index 999999

.wrap
  position relative
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top 7.7rem

  @media (min-width: 640px)
    padding-top 9.5rem

  @media (min-width: $widthMobile)
    padding-top 0
    padding-left 31.25%

.box
  padding-left 2rem
  padding-right 2rem
  max-width 103rem
  width 100%

  @media (min-width: 1080px)
    padding-left cw(60)
    padding-right cw(60)

.sublink
  fs(18)
  color var(--color-link)
  transition .32s
  position relative
    
  @media (min-width: $widthMobile)
    fs(14)

  &--big
    @media (min-width: $widthMobile)
      fs(18)

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      color var(--color-link)
  
  &:before
    content: ''
    position: absolute
    background: var(--color-link)
    left 0
    bottom: 0
    width: 100%
    opacity 1
    height: .1rem
    transition: .32s
    
    @media (min-width: $widthMobile)
      width 0
      opacity .2

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      width: 100%
      opacity 1

.title
  fs(36)
  fw(5)
  color var(--color-title)
  padding-bottom .2rem
  line-height: 1.4
    
  @media (min-width: $widthMobile)
    fs(26)

.text
  line-height: 1.5
  fw(3)

  &>*:nth-child(n+2)
    margin-top cw(18)

  i
    font-style: italic

  table
    overflow: hidden;

  th
    fs(20)
    fw(5)
    padding cw(16) cw(20)
    text-align: left

  td
    padding cw(12) cw(20)
    border .1rem solid var(--color-bord)

  ul
    display grid
    flex-direction column
    gap cw(20) 0

    li
      position: relative
      padding-left cw(15)

      &:before
        content: '•'
        position: absolute
        left 0
        top 0

  &>a
    display: block

  a
    color var(--color-link)

  img + p:nth-child(n+2)
    margin-top cw(30)

  h2:nth-child(n+2),
  h3:nth-child(n+2),
  h4:nth-child(n+2),
  h5:nth-child(n+2),
  h6:nth-child(n+2)
    padding-top cw(22)

  h2,h3,h4,h5,h6
    text-transform: uppercase
    fw(5)
    color var(--color-title)
    padding-bottom .2rem
    line-height: 1.4

  h2
    fs(26)

  h3
    fs(24)

  h4
    fs(22)

  h5
    fs(20)

  h6
    fs(18)

  iframe
    max-width 100%

.alignright
  float right
  margin-left 4.7rem
  margin-bottom 1.5rem

  @media (max-width: 640px)
    float none
    margin-left 0
    margin-bottom 0

.alignleft
  float left
  margin-right 2.7rem
  margin-bottom 1.5rem

  @media (max-width: 640px)
    float none
    margin-right 0
    margin-bottom 0

.pagination
  display: flex
  flex-wrap wrap
  justify-content: center
  align-items center
  padding: cw(60) 0 0
  fs(14)

  &__item
    border-radius: 50%
    border .1rem solid rgba(0,0,0,.08)
    display: flex
    align-items: center
    justify-content: center
    padding: cw(3)
    min-width: 4rem
    min-height: 4rem
    transition: .32s
    margin: .6rem .3rem

    &:hover
      background: var(--color-black)
      border-color var(--color-black)
      color var(--color-white)

    &.active
      cursor: default
      border-color var(--color-black)
      color var(--color-black)

      &:hover
        background inherit

  &__left,
  &__right
    line-height 0
    padding: cw(8)

  &__right
    transform scale(-1)

  &__arr
    fill var(--color-copy)
    transition: .32s

  a:hover &__arr
    fill var(--color-link)

.btn
  display inline-grid
  grid-auto-flow: column
  gap cw(10)
  align-items: center
  justify-content: center
  color var(--color-text)
  background var(--color-btn)
  border .1rem solid var(--color-btn)
  transition all .32s
  padding cw(5) cw(14)
  text-align center
  font-family $fontIT
  min-height cw(32)
  cursor pointer
  padding: .6rem 1.5rem
  fill var(--color-text)

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      color var(--color-title)
      fill var(--color-title)
      border .1rem solid var(--color-text)

  &.disabled,
  &:hover.disabled
    cursor default
    opacity .35
    color var(--color-text)
    fill var(--color-text)
    border .1rem solid var(--color-btn)

.logo
  display: block
  max-width 12.8rem

  @media (min-width: 640px)
    max-width 16.8rem

.nav
  &__ul
    display flex
    flex-direction: column
    gap cw(22) 0
    position relative
    text-transform: uppercase
    min-height: 100%
    fs(22)

    @media (min-width: $widthMobile)
      fs(16)
      gap cw(15) 0

  &__li
    &--break
      margin-top auto
      padding-top cw(20)

      @media (min-width: $widthMobile)
        padding-top cw(38)

    &nk
      position relative

      &:before
        @media (hover: hover) and (min-width: $widthMobile)
          content: ''
          position: absolute
          left: 0
          bottom: 0
          opacity .2
          transition: .32s
          width: 0
          height: .1rem
          background: var(--color-white)

      &:hover:before
        @media (hover: hover) and (min-width: $widthMobile)
          opacity 1
          width: 100%

.soc
  display: grid
  grid-template-columns: repeat(5, 1fr)
  gap cw(21) cw(36)
  align-items: center;

.ico
  &Arr
    width: 1.5rem
    height: 1.2rem

  &Cross
    width: 1.4rem
    height: 1.4rem



/* SWIPER */

.slider
  position relative
  height: 100%
  width: 100%

  &__wrap
    position relative
    z-index 1
    overflow hidden
    width 100%
    touch-action pan-y
    height: 100%

  &__inner
    display flex
    box-sizing content-box
    width 100%
    height 100%
    transition-property transform

  &__slide
    flex-shrink 0
    height: 100%
    width: 100%

  &__pagination
    position absolute
    display: grid
    grid-auto-flow: column;
    gap cw(12)
    z-index 2
    left: cw(52)
    bottom: cw(26)

.bullet
  height: .2rem
  background: var(--color-white)
  opacity .5
  width: cw(30)
  transition: .32s

  &.active
    opacity 1
    width: cw(64)


/* HEADER */

.header
  position absolute
  top 0
  left 0
  right 0
  z-index 3
  background var(--color-menu)
  color var(--color-white)
  padding 1rem 2rem

  @media (min-width: $widthMobile)
    position fixed
    left 0
    top 0
    right auto
    width: 31.25%
    height: 100%;
    padding: cw(20)

  &.open
    position fixed
    height: 100%;
    overflow: auto

    @media (min-width: $widthMobile)
      height auto

  &.fixed
    position fixed

  &__box
    @media (min-width: $widthMobile)
      display: grid
      justify-content: center;
      height 100%
      padding: cw(40) cw(20) cw(20)
      grid-template-rows: auto 1fr
      overflow: auto

    &::-webkit-scrollbar
      width .3rem

    &::-webkit-scrollbar-track
      background: rgba(255,255,255,.05)

    &::-webkit-scrollbar-thumb
      background: var(--color-white)

  &__soc
    @media (min-width: $widthMobile)
      margin-top 18%

  &__logo
    margin: auto

    @media (min-width: $widthMobile)
      margin-bottom 18%

  &__inner
    display none
    justify-content: center;
    gap cw(34) 0
    padding: cw(38) 2rem

    @media (min-width: $widthMobile)
      display: grid
      background: transparent
      grid-template-rows: 1fr auto
      margin 0
      padding 0
      gap 0

  &.open &__inner
    display grid

  &__nav
    min-width: 20.8rem
    padding 0 cw(44)

    &Toggle
      position absolute
      width 4rem
      height 3rem
      z-index 4
      top 50%
      top cw(64)
      transform: translate(0,-50%)
      right: 2rem

      @media (min-width: $widthMobile)
        display none

      &:before,
      &:after
        content ""

      &:before,
      &:after,
      span
        position absolute
        height .4rem
        width 100%
        background #fff
        left 0
        border-radius .2rem
        transition .3s all

      &:before
        top 0

      &:after
        bottom 0

      span
        top 50%
        left 50%
        transform translate(-50%, -50%)

      &.open span
        opacity 0
        width 0

      &.open:before
        transform rotate(-45deg) translate(-0.55rem, 1.3rem)

      &.open:after
        transform rotate(45deg) translate(-.55rem, -1.25rem)



/* FOOTER */

.footer
  padding: 0 0 cw(50)
  color var(--color-copy)
  text-align: center
  fs(14)
  margin-top: auto;

  &__box
    display: grid
    gap cw(10)

.upper
  display: grid
  padding cw(25) 0 cw(12)
  justify-content: center
  align-items: center
  transition: .32s

  &:before
    content: ''
    border .1rem solid var(--color-text)
    border-left 0
    border-bottom 0
    transform: rotate(-45deg)
    transition: .32s
    width 1.5rem
    height 1.5rem

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      transform scale(1.2) rotate(-45deg)






/* CONTENT */

.content
  position relative
  padding .1rem 0 cw(44)
  display flex
  flex-direction: column
  gap cw(66)

  @media (min-width: $widthMobile)
    padding: cw(44) 0
    gap cw(40)

.top,
.banner
  &__box
    padding: 0;

    @media (min-width: $widthMobile)
      padding: 0 2rem

    @media (min-width: 1080px)
      padding: 0 cw(60)

.top
  &__box
    display: grid
    gap cw(20)

  &__back
    padding: 0 2rem;

    @media (min-width: $widthMobile)
      padding: 0;

  &__img
    width: 100%

  &__menu,
  &__submenu
    padding-left 2rem
    padding-right 2rem

    @media (min-width: $widthMobile)
      padding-left 0
      padding-right 0

.banner
  &__slide
    position: relative

  &__text
    padding: cw(52)
    position: absolute
    top: 50%;
    transform: translate(0, -50%);
    fw(5)
    fs(32)
    line-height: 1.33
    color var(--color-white)
    text-shadow: 0 0 .3rem rgba(0,0,0,.3)

.brands
  &__box
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap cw(48) cw(40)
    fs(14)
    
    @media (min-width: 540px)
      grid-template-columns: repeat(3, 1fr)

    @media (min-width: $widthMobile)
      gap cw(30) cw(40)
      grid-template-columns: repeat(4, 1fr)

    @media (min-width: 1200px)
      grid-template-columns: repeat(6, 1fr)

.brand
  display: grid
  color var(--color-copy)
  gap cw(10)
  transition: .32s
  justify-content: center
  align-items: end
  text-align: center;

  &__img
    margin 0 auto

  &__text
    align-self: end;
    white-space: nowrap
    margin: 0 auto;
    border-bottom: .1rem solid var(--color-copy)
    transition: .32s
    line-height: 1
    
  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      color var(--color-title)

  &:hover &__text
    @media (hover: hover) and (min-width: $widthMobile)
      border-bottom: .1rem solid rgba(255,255,255,0)

.about
  &__title
    padding: cw(4) 0 cw(20)
    text-transform: uppercase

  &__sublink
    padding: cw(20) 0 0

.news
  &__title
    text-transform: uppercase

  &__items
    display: grid
    gap cw(38) cw(26)
    padding: cw(20) 0 0
    
    @media (min-width: 680px)
      grid-template-columns: repeat(3, 1fr)

  &Item
    line-height: 1.375
    display: grid
    gap cw(16)

    &__img
      width: 100%;

      @media (min-width: 680px)
        height cw(190)
        object-fit cover
        
      @media (min-width: $widthMobile)
        height cw(160)

    &__title
      padding cw(2) 0 0

    &__date
      color var(--color-copy)
      fs(14)
      margin-top: auto;

  &__sublink
    padding: cw(31) 0 0

  &Page
    &__top
      padding: cw(16) 0 cw(40)
      display: flex
      flex-wrap: wrap
      gap cw(18) 0

    &__date
      color var(--color-copy)
      fs(18)
      fw(5)
      padding-right: cw(37)

    &__link
      @media (min-width: $widthMobile)
        fs(18)

.contacts
  &__items
    display: flex
    flex-direction: column
    gap cw(22)
    padding: cw(28) 0 cw(46)

.contact
  &__name
    color: var(--color-copy)
    padding: 0 0 cw(6)
    line-height: 1.375

  &__text
    fw(3)
    line-height: 1.5

.listNews
  &__title
    padding: 0 0 cw(36)

  &__brands
    display: grid
    gap cw(40)

  &__brand
    display: grid
    gap cw(16)
    
  &__items,
  &__bottom
    display: grid
    
    @media (min-width: 580px)
      grid-template-columns: repeat(2, 1fr)

  &__items
    gap cw(48) cw(26)

  &__item
    display: flex
    flex-direction: column

  &__img
    width: 100%;

    @media (min-width: 580px)
      height cw(220)
      object-fit cover

  &__date
    padding: cw(16) 0 cw(8)
    color: var(--color-copy)
    fs(14)

  &__name
    line-height: 1.375
    margin-bottom: auto
    padding 0 0 cw(12)
    color var(--color-title)

  &__desc
    line-height: 1.57
    fw(3)
    fs(14)

  &__bottom
    gap cw(12) cw(26)

.trade
  &__title
    padding-bottom: cw(20)
    text-transform: uppercase

  &__items
    display: grid
    gap cw(16)
    padding-top cw(40)

  &__city
    position relative
    background: var(--color-bord)
    padding cw(10) cw(40)
    fs(22)
    fw(5)
    line-height: 1.64
    transition: .32s
    cursor pointer

    &:before
      content: ''
      position: absolute
      top 50%
      right 2.4rem
      transform: translate(0,-75%) rotate(-135deg)
      border-left .1rem solid var(--color-text)
      border-top .1rem solid var(--color-text)
      width 1.5rem
      height 1.5rem
      transition: .32s

    &.active:before
      border-color var(--color-white)
      transform: translate(0,-25%) rotate(45deg)

    &.active
      background: var(--color-title)
      color var(--color-white)

  &__inner
    display: none
    padding: 0 0 cw(20)

  &__subtitle
    padding: cw(30) 0 cw(20)
    fs(18)
    fw(5)
    color var(--color-title)

  &__list
    display: grid
    gap cw(20) cw(26)
    
    @media (min-width: 580px)
      grid-template-columns: repeat(2, 1fr)

    @media (min-width: 880px)
      grid-template-columns: repeat(3, 1fr)

.point
  padding: cw(26) cw(20)
  background: var(--color-bord)
  display: flex
  flex-direction: column
  fs(14)

  &__title
    fs(16)
    color var(--color-title)
    padding: 0 0 cw(12)
    line-height: 1.375

  &__desc
    fw(3)
    padding: 0 0 cw(10)
    line-height: 1.43

  &__phones
    display: grid
    color var(--color-title)
    fw(5)
    padding: 0 0 cw(10)
    line-height: 1.57

  &__site
    margin-top: auto
    color var(--color-link)

.tehs
  &__title
    padding: 0 0 cw(8)
    
  &__item
    padding: cw(22) 0
    display: grid
    gap cw(8) cw(27)
    
    @media (min-width: 480px)
      grid-template-columns: 14.3% auto

    &:nth-child(n+2)
      border-top .1rem solid var(--color-btn)

  &__wrap
    padding: 0 0 cw(12)

    @media (min-width: 480px)
      display: flex
      align-items: center
      justify-content: center
      grid-row: 1/4
      padding: 0

  &__date
    color var(--color-copy)
    fs(14)
    line-height 1.285

  &__name
    color var(--color-title)
    line-height: 1.375

  &__desc
    fs(14)
    fw(3)
    line-height: 1.57

.tehp
  &__title
    text-transform: uppercase
    padding: 0 0 cw(16)

  &__date
    color: var(--color-copy)
    fs(18)
    fw(5)
    
  &__text
    padding: cw(40) 0 0

.company
  &__title
    padding: 0 0 cw(30)

.product
  &__top
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    padding: 0 0 cw(30)

  &__title
    padding-right 3rem

  &__anons
    fw(3)

.teh
  padding: cw(40) 0 0

  &__inner
    padding: 0 0 cw(23)
    display: grid
    gap cw(48) cw(26)
    
    @media (min-width: 640px)
      align-items: start;
      grid-template-columns: auto 20.8rem

  &__right
    display: grid
    gap cw(34)

  a
    color var(--color-link)

  &__left
    display: grid
    
    @media (min-width: 1180px)
      grid-template-columns: repeat(2, 1fr)

  &__item
    border-bottom: .1rem solid var(--color-btn)
    padding: cw(12) 0

  &__title
    padding 0 0 cw(8)
    text-transform: uppercase
    
    @media (min-width: 1180px)
      padding 0 0 cw(8)

  &__name
    color var(--color-copy)
    padding 0 0 cw(6)
    line-height: 1.375

  &__data
    fw(3)
    line-height: 1.5

.add
  &__name
    text-transform: uppercase
    padding: 0 0 cw(12)
    fs(18)
    fw(5)
    color var(--color-title)

.menu
  display: flex
  flex-wrap: wrap

  &__item
    background: var(--color-black)
    padding: cw(3) cw(9)
    border .1rem solid var(--color-black)
    text-transform: uppercase
    color var(--color-white)
    fs(14)
    line-height: 1.43
    transition: .32s
    margin-right 1rem
    margin-bottom: cw(12)

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        background: var(--color-white)
        color var(--color-black)

.submenu
  fw(3)
  padding-top cw(12)
  padding-bottom cw(8)
  border-bottom: .1rem solid var(--color-btn)

  &__item
    display inline-block
    padding cw(8) 0
    margin-right cw(32)
    position: relative

    &:before
      content: ''
      position: absolute
      background: var(--color-text)
      left 0
      bottom: cw(8)
      width: 100%
      opacity 1
      height: .1rem
      transition: .32s
      
      @media (min-width: $widthMobile)
        width 0
        opacity .2

    &:hover:before
      @media (hover: hover) and (min-width: $widthMobile)
        width: 100%
        opacity 1

    &.active
      fw(5)

  &__cross
    display inline-block
    fill #b71818
    width 0
    margin 0
    opacity 0
    transition .32s

  &__item.active &__cross
    width 1.4rem
    margin 0 0 -.1rem .6rem
    opacity 1

.products
  &__row
    display none

    &.active
      display block

  &__title
    text-transform: uppercase
    padding: 0 0 cw(20)

  &__items
    display grid
    gap cw(40) cw(26)
    padding: 0 0 cw(40)
    grid-template-columns repeat(2, 1fr)

    @media (min-width: 540px)
      grid-template-columns repeat(3, 1fr)

    @media (min-width: 780px)
      grid-template-columns repeat(4, 1fr)

  &__item
    display none
    fw(3)
    gap cw(12)
    transition: .32s

    &.active
      display grid

    &:hover
      color var(--color-link)

  &__name
    line-height: 1.5

.archive
  &__wrap
    background: var(--color-bord)
    padding-bottom: cw(20)

  &__top
    position relative
    padding cw(50) 2rem cw(30)
    cursor pointer

    @media (min-width: 1080px)
      padding cw(50) cw(52) cw(30)

    &:before
      content: ''
      position: absolute
      top 50%
      right 6.2rem
      transform: translate(0,-25%) rotate(-135deg)
      border-left .1rem solid var(--color-text)
      border-top .1rem solid var(--color-text)
      width 1.5rem
      height 1.5rem
      transition: .32s

    &.active:before
      transform: translate(0,25%) rotate(45deg)

  &__title
    text-transform: uppercase

  &__inner
    display: none
    padding 0 2rem

    @media (min-width: 1080px)
      padding 0 cw(52)

  &__items
    padding: cw(30) 0 cw(10)
    display: grid
    gap cw(30) cw(26)

    @media (min-width: 380px)
      grid-template-columns repeat(2, 1fr)

    @media (min-width: 540px)
      grid-template-columns repeat(3, 1fr)

    @media (min-width: 780px)
      grid-template-columns repeat(4, 1fr)

  &__item
    display: flex
    flex-direction: column
    gap cw(16)
    fs(14)
    transition: .32s

    &:hover
      color var(--color-link)

  &__name
    line-height: 1.43
    margin-top auto

.answer
  fs(20)
  fw(5)
  padding: cw(20) 0

  &--send
    color green

  &--error
    color red

.form
  padding: cw(16) 0 0
  max-width: 54rem

  &__label
    color var(--color-text)
    font-family $fontC
    letter-spacing .04rem
    color var(--color-copy)

  &__select,
  &__input,
  &__textarea
    margin cw(4) 0 cw(20)
    border .1rem solid var(--color-copy)
    font-family $fontM
    width 100%
    fs(16)
    padding cw(12) cw(8)

    &.error
      background red

    &::placeholder
      color var(--color-text)

  &__select
    cursor pointer
    appearance none
    position relative
    background: url(../images/ico_select.svg) no-repeat
    background-position: right 1.5rem top 50%;

  &__input,
  &__textarea
    &--hide
      display none

  &__textarea
    padding cw(10)
    min-height 9.8rem
    line-height 1.5

  &__accept
    display: block
    fs(12)
    color var(--color-link)
    margin cw(16) 0 0

  &__hide
    display none
      
  &__submit
    margin cw(30) auto 0

  &__btn
    width: 100%
    padding-top cw(20)
    padding-bottom cw(16)
    min-height 5.2rem
    text-transform: uppercase

    @media (min-width: $widthMobile)
      min-height none

    &Wrap
      display: flex
      align-items: flex-end
      padding-top cw(12)
      
      @media (min-width: 900px)
        padding-top 0